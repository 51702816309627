import styles from "./CandidateProfile.module.css";
import CandidateProfileCard from "../../components/CandidateProfileCard/CandidateProfileCard";
import useAuth from "../../utils/hooks/useAuth";
import Error403 from "../../components/Errors/403/Error403";
import Loading from "../../components/Loading/Loading";
import Toast from "../../components/Toast/Toast";

const CandidateProfile = () => {
  const validation = useAuth();
  if (!validation || validation === "Loading") {
    return <Loading />;
  } else if (validation === "Not authorized") {
    return <Error403 />;
  } else {
    return (
      <div>
        <Toast />
        <h1 className={styles.title}>Edit Profile</h1>
        <p className={styles.description}>Add all of your information here!</p>
        <div className={styles.container}>
          <CandidateProfileCard />
        </div>
      </div>
    );
  }
};

export default CandidateProfile;
