import "./global.css";
import CandidateDetails from "./pages/CandidateDetails/CandidateDetails";
import CandidateProfile from "./pages/CandidateProfile/CandidateProfile";
import EmployerProfile from "./pages/EmployerProfile/EmployerProfile";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import Option from "./pages/Option/Option";
import Home from "./pages/Home/Home";
import Error404 from "./components/Errors/404/Error404";
import Pricing from "./pages/Pricing/Pricing";
import logo from "./assets/images/NoWhiteboardDevsLogo(dark_blue_bg).png";
import { FirebaseProvider } from "./utils/contexts/FirebaseContext";
import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import PendingApproval from "./pages/Approval/PendingApproval";
import CandidatePendingApproval from "./pages/CandidatePendingApproval/CandidatePendingApproval";

ReactGA.initialize("G-HRLLVRF4CD");

const App = () => {
  return (
    <FirebaseProvider>
      <BrowserRouter>
        <div className="background">
          <div className="table">
            <Link to="/">
              <img className="logo" src={logo} alt="NoWhiteboardDevsLogo" />
            </Link>
            <h1 className="title">NoWhiteboardDevs</h1>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/option" element={<Option />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/candidateprofile" element={<CandidateProfile />} />
              {
                //<Route path="/employerprofile" element={<EmployerProfile />} />
              }
              <Route path="/candidate/:id" element={<CandidateDetails />} />
              {
                //<Route path="/pricing" element={<Pricing />} />
              }
              <Route path="/pendingApproval" element={<PendingApproval />} />
              <Route
                path="/pendingApproval/:id"
                element={<CandidatePendingApproval />}
              />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </FirebaseProvider>
  );
};

export default App;
