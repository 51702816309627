import { useState } from "react";
import styles from "./SignInCard.module.css";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useFirebase } from "../../utils/contexts/FirebaseContext";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const SignInCard = () => {
  const { auth, db } = useFirebase();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSignIn = async (event) => {
    event.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const docRef = doc(db, "users", user.uid);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        if (
          !user.emailVerified &&
          (userData.role === "empty" || userData.role === "candidate")
        ) {
          localStorage.setItem("toastType", "good");
          localStorage.setItem(
            "toastMessage",
            "Remember, a verification link was sent to your email. Your account must be verified before it can be viewed by companies. You may need to log out and back in if you have already verified your email."
          );
        }
        if (userData.role === "empty") {
          navigate("/option");
        } else {
          navigate("/");
        }
      } else {
        console.error("User document not found!");
      }

      setEmail("");
      setPassword("");
    } catch (error) {
      setError(error.message);
      setTimeout(() => {
        setError("");
      }, 3000);
      console.error("Error signing in:", error.message);
    }
  };

  return (
    <div className={styles.card}>
      <h3 className={styles.h3}>Sign In</h3>
      <form onSubmit={handleSignIn}>
        <div className={styles.item}>
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={handleEmailChange}
            required
            className={styles.input}
          />
        </div>

        <div className={styles.item}>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            required
            className={styles.input}
          />
        </div>

        <button type="submit" className={styles.button}>
          Sign In
        </button>
      </form>
      {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
    </div>
  );
};

export default SignInCard;
