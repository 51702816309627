import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFirebase } from "../contexts/FirebaseContext";
import axios from "axios";

const useAuthHome = () => {
  const { user, loading } = useFirebase();
  const navigate = useNavigate();
  const location = useLocation();
  const [access, setAccess] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://us-central1-nowhiteboarddevs.cloudfunctions.net/canViewCandidate",
          {
            userId: user ? user.uid : null,
            candidateUserId:
              location.pathname.split("/")[2] !== "/" &&
              location.pathname.split("/")[2] !== null &&
              location.pathname.split("/")[2] !== ""
                ? location.pathname.split("/")[2]
                : null,
            page: location.search.split("=")[1]
              ? location.search.split("=")[1]
              : "/",
          }
        );

        if (response.status === 200) {
          setAccess("granted");
        } else if (response.status === 403) {
          setAccess("denied");
        }
      } catch (error) {
        setAccess("denied");
      }
    };

    if (!loading) {
      fetchData();
    }
  }, [location.search, location.pathname, navigate, user, loading]);

  if (loading || !access) {
    return "Loading";
  } else if (access === "denied") {
    return "Not authorized";
  } else if (access === "granted") {
    return "Authorized";
  }
};

export default useAuthHome;
