import { useState } from "react";
import styles from "./SignUpCard.module.css";
import { useFirebase } from "../../utils/contexts/FirebaseContext";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Toast from "../Toast/Toast";

const SignUpCard = () => {
  const { auth, db } = useFirebase();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    try {
      if (password !== confirmPassword) {
        setError("Passwords don't match. Please re-enter your password");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          const userDocRef = doc(db, "users", user.uid);
          setDoc(userDocRef, { email, role: "empty", id: user.uid });
          sendEmailVerification(userCredential.user)
            .then(() => {
              localStorage.setItem("toastType", "good");
              localStorage.setItem(
                "toastMessage",
                "A verification link has been sent to your email. Your account must be verified before it can be viewed by companies."
              );
              navigate("/option");
            })
            .catch((error) => {
              console.error("Error sending email verification link: ", error);
            });
        })
        .catch((error) => {
          setError(error.message);
          setTimeout(() => {
            setError("");
          }, 3000);
          console.error("Error signing up:", error.message);
        });
    } catch (error) {
      setError(error.message);
      setTimeout(() => {
        setError("");
      }, 3000);
      console.error("Error signing up:", error.message);
    }
  };

  return (
    <div className={styles.card}>
      <h3 className={styles.h3}>Sign Up</h3>
      <form onSubmit={handleSignUp}>
        <div className={styles.item}>
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={handleEmailChange}
            required
            className={styles.input}
          />
        </div>

        <div className={styles.item}>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            required
            className={styles.input}
          />
        </div>

        <div className={styles.item}>
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
            className={styles.input}
          />
        </div>

        <button type="submit" className={styles.button}>
          Join
        </button>
      </form>
      {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
    </div>
  );
};

export default SignUpCard;
