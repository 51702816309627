import Loading from "../../components/Loading/Loading";
import Error403 from "../../components/Errors/403/Error403";
import CandidateDetailsCard from "../../components/CandidateDetailsCard/CandidateDetailsCard";
import Toast from "../../components/Toast/Toast";
import styles from "./CandidatePendingApproval.module.css";
import ApprovalCard from "../../components/ApprovalCard/ApprovalCard";
import useAuth from "../../utils/hooks/useAuth";

const CandidatePendingApproval = () => {
  const validation = useAuth();
  if (!validation || validation === "Loading") {
    return <Loading />;
  } else if (validation === "Not authorized") {
    return <Error403 />;
  } else {
    return (
      <div>
        <Toast />
        <h1 className={styles.title}>Candidate Pending Approval</h1>
        <p className={styles.description}>
          Details about this candidate pending approval
        </p>
        <div className={styles.container}>
          <CandidateDetailsCard />
        </div>
        <div className={styles.container}>
          <ApprovalCard />
        </div>
      </div>
    );
  }
};

export default CandidatePendingApproval;
