import CandidateCard from "../../components/CandidateCard/CandidateCard";
import styles from "./Home.module.css";
import { useFirebase } from "../../utils/contexts/FirebaseContext";
import {
  collection,
  getDocs,
  getDoc,
  limit,
  startAt,
  orderBy,
  query,
  where,
  doc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ref, getDownloadURL } from "firebase/storage";
import Pagination from "../../components/Pagination/Pagination";
import Loading from "../../components/Loading/Loading";
import useAuthHome from "../../utils/hooks/useAuthHome";
import Error403 from "../../components/Errors/403/Error403";
import Toast from "../../components/Toast/Toast";

const Home = () => {
  const location = useLocation();
  const { db, user, loading, storage } = useFirebase();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [imageUrls, setImageUrls] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const authHome = useAuthHome();
  const [totalCandidates, setTotalCandidates] = useState(null);
  const [candidatesCache, setCandidatesCache] = useState({});
  const profilesPerPage = 10;
  const currentCandidates = candidatesCache[currentPage]?.candidates || [];
  useEffect(() => {
    const fetchCandidatesCount = async () => {
      try {
        const approvedCandidateCountDocRef = doc(
          db,
          "metadata",
          "approvedCandidateCount"
        );
        const approvedCandidateCountDoc = await getDoc(
          approvedCandidateCountDocRef
        );
        const approvedCandidateCount = approvedCandidateCountDoc.data();

        setTotalCandidates(approvedCandidateCount.count);
      } catch (error) {}
    };
    const fetchPage = async () => {
      const pageParam = new URLSearchParams(location.search).get("page");
      const newPage = pageParam ? parseInt(pageParam, 10) : 1;
      setCurrentPage(newPage);
    };
    const fetchImage = async (userId) => {
      try {
        const storageRef = ref(
          storage,
          `approvedCandidates/${userId}/images/${userId}`
        );

        const url = await getDownloadURL(storageRef);
        setImageUrls((prevImageUrls) => ({
          ...prevImageUrls,
          [userId]: url,
        }));
      } catch (error) {
        console.error(`Error fetching images`);
      }
    };
    const fetchCandidatesForPage = async (pageNumber) => {
      try {
        const approvedCandidatesCollectionRef = collection(
          db,
          "approvedCandidates"
        );
        const approvedCandidatesQuery = query(
          approvedCandidatesCollectionRef,
          orderBy("index"),
          limit(profilesPerPage),
          startAt(profilesPerPage * (pageNumber - 1))
        );
        const candidatesSnapshot = await getDocs(approvedCandidatesQuery);
        const candidates = candidatesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCandidatesCache((prevCache) => ({
          ...prevCache,
          [pageNumber]: {
            candidates,
            lastVisibleDoc:
              candidatesSnapshot.docs[candidatesSnapshot.docs.length - 1] ||
              null,
          },
        }));
        if (user) {
          const usersQuery = query(
            collection(db, "users"),
            where("id", "==", user.uid),
            limit(1)
          );

          const usersSnapshot = await getDocs(usersQuery);

          if (usersSnapshot.size > 0) {
            const userData = usersSnapshot.docs[0].data();
            setUserData(userData);
          }
        }

        candidates.forEach((candidate) => {
          if (!imageUrls[candidate.id] && candidate.hasProfilePic) {
            fetchImage(candidate.id);
          }
        });
      } catch (error) {
        console.error("Error fetching candidate data");
      }
    };

    fetchPage();
    if (!totalCandidates) {
      fetchCandidatesCount();
    }
    if (!candidatesCache[currentPage]) {
      fetchCandidatesForPage(currentPage);
    }
  }, [
    currentPage,
    location,
    candidatesCache,
    db,
    imageUrls,
    storage,
    totalCandidates,
    user,
    authHome,
  ]);

  const generalizeProfile = (candidate) => {
    const newCandidate = {
      ...candidate,
      firstName: "",
      lastName: "",
      email: "",
      hasResume: "",
    };

    return newCandidate;
  };

  if (loading || authHome === "Loading") {
    return <Loading />;
  } else {
    return (
      <div>
        <Toast />
        {!loading && !user && (
          <nav className={styles.nav}>
            <ul style={{ listStyleType: "none" }}>
              <li className={styles.navItems}>
                {/*<Link to="/signup">
                  <button className={styles.button}>Start Hiring</button>
                </Link>
                */}
              </li>
            </ul>
            <ul
              style={{
                listStyleType: "none",
                display: "flex",
                marginRight: "3rem ",
              }}
            >
              <li className={styles.navItems}>
                <Link to="/signup">
                  <button className={styles.button}>Sign Up</button>
                </Link>
              </li>
              <li className={styles.navItems}>
                <Link to="/signin">
                  <button className={styles.button}>Sign In</button>
                </Link>
              </li>
            </ul>
          </nav>
        )}

        {!loading && user && (
          <nav className={styles.nav}>
            <ul style={{ listStyleType: "none" }}>
              <li className={styles.navItems}>
                {userData.role === "company" &&
                  {
                    /*<Link to="/pricing">
                    <button className={styles.button}>Start Hiring</button>
                  </Link>
                  */
                  }}
                {userData.role === "admin" && (
                  <Link to="/pendingApproval">
                    <button className={styles.button}>Pending Approval</button>
                  </Link>
                )}
              </li>
            </ul>
            <ul
              style={{
                listStyleType: "none",
                display: "flex",
                marginRight: "3rem ",
              }}
            >
              <li className={styles.navItems}>
                <button
                  className={styles.button}
                  onClick={() => {
                    if (userData.role === "empty") {
                      navigate("/option");
                    } else if (userData.role === "candidate") {
                      navigate("/candidateprofile");
                    } else if (userData.role === "company") {
                      navigate("/employerprofile");
                    }
                  }}
                >
                  Profile
                </button>
              </li>
              <li className={styles.navItems}>
                <button
                  className={styles.button}
                  onClick={() => {
                    const auth = getAuth();
                    signOut(auth);
                  }}
                >
                  Logout
                </button>
              </li>
            </ul>
          </nav>
        )}

        <h3
          style={{
            color: "white",
            marginLeft: "2rem",
            textAlign: "center",
          }}
        >
          Developers For Hire
        </h3>
        {!loading &&
        authHome === "Authorized" &&
        (userData.role === "company" || userData.role === "admin")
          ? currentCandidates.map((candidate) => (
              <CandidateCard
                key={candidate.id}
                candidate={candidate}
                imageUrl={imageUrls[candidate.id]}
                page={currentPage}
                hasResume={candidate.hasResume}
              />
            ))
          : !loading && authHome === "Authorized" && currentPage === 1
          ? currentCandidates.map((candidate) => (
              <CandidateCard
                key={candidate.id}
                candidate={generalizeProfile(candidate)}
                imageUrl={imageUrls[candidate.id]}
                page={currentPage}
                hasResume={candidate.hasResume}
              />
            ))
          : authHome === "Not authorized" && <Error403 />}
        {!loading && totalCandidates && (
          <Pagination
            totalItems={totalCandidates}
            itemsPerPage={profilesPerPage}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            authorized={
              userData.role === "company" || userData.role === "admin"
            }
          />
        )}
      </div>
    );
  }
};

export default Home;
