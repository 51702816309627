import styles from "./CandidateDetails.module.css";
import CandidateDetailsCard from "../../components/CandidateDetailsCard/CandidateDetailsCard";
import useAuthHome from "../../utils/hooks/useAuthHome";
import Loading from "../../components/Loading/Loading";
import Error403 from "../../components/Errors/403/Error403";
import Toast from "../../components/Toast/Toast";

const CandidateDetails = () => {
  const validation = useAuthHome();
  if (!validation || validation === "Loading") {
    return <Loading />;
  } else if (validation === "Not authorized") {
    return <Error403 />;
  } else {
    return (
      <div>
        <Toast />
        <h1 className={styles.title}>Candidate Details</h1>
        <p className={styles.description}>Details about this candidate</p>
        <div className={styles.container}>
          <CandidateDetailsCard />
        </div>
      </div>
    );
  }
};

export default CandidateDetails;
