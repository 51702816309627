import React from "react";
import errorImage from "../../../assets/images/404_error.png";

const Error404 = () => {
  return (
    <div>
      <h1 style={{ textAlign: "center", color: "white" }}>
        Error 404: Not Found
      </h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={errorImage}
          alt="404 error"
          style={{ width: "500px", background: "white", borderRadius: "10px" }}
        />
      </div>
    </div>
  );
};

export default Error404;
