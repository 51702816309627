import React, { useEffect, useState } from "react";
import styles from "./Toast.module.css";

const getToastClassName = (isVisible, toastType) => {
  if (isVisible && toastType === "good") {
    return styles.showGoodToast;
  } else if (!isVisible && toastType === "good") {
    return styles.goodToast;
  } else if (isVisible && toastType === "bad") {
    return styles.showBadToast;
  } else if (!isVisible && toastType === "bad") {
    return styles.badToast;
  } else {
    return "";
  }
};

const Toast = () => {
  const [toastMessage, setToastMessage] = useState(null);
  const [isVisible, setIsVisible] = useState(null);
  const toastType = localStorage.getItem("toastType");
  const updateToast = () => {
    const storedMessage = localStorage.getItem("toastMessage");
    if (storedMessage) {
      setToastMessage(storedMessage);
      setIsVisible(true);

      console.log("toastType: ", toastType);

      setTimeout(() => {
        setIsVisible(false);
        localStorage.removeItem("toastType");
        localStorage.removeItem("toastMessage");
        setToastMessage(null);
      }, 10000);
    }
  };
  useEffect(() => {
    updateToast();
    window.addEventListener("storage", updateToast);
    return () => window.removeEventListener("storage", updateToast);
  }, []);
  return (
    <div className={getToastClassName(isVisible, toastType)}>
      <div className={styles.content}>{toastMessage}</div>
    </div>
  );
};

export default Toast;
