import backBlack from "../../assets/images/back_black.png";
import { useNavigate } from "react-router-dom";
import styles from "./BackButton.module.css";

const BackButton = ({ path }) => {
  const navigate = useNavigate();

  return (
    <img
      src={backBlack}
      className={styles.icon}
      alt="back button"
      onClick={() => navigate(path)}
    />
  );
};

export default BackButton;
