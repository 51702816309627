import styles from "./SignIn.module.css";
import SignInCard from "../../components/SignInCard/SignInCard";
import { Link } from "react-router-dom";
import Toast from "../../components/Toast/Toast";

const SignIn = () => {
  return (
    <div>
      <Toast />
      <h1 className={styles.title}>Sign in to your account</h1>
      <p className={styles.accountText}>
        Don't have an account?
        <Link to="/signup" className={styles.link}>
          {" "}
          Click Here
        </Link>
      </p>
      <div className={styles.container}>
        <SignInCard />
      </div>
    </div>
  );
};

export default SignIn;
