import styles from "./OptionCard.module.css";

const OptionCard = (props) => {
  const { title, description } = props;

  return (
    <div className={styles.card}>
      <div>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}> {description}</p>
      </div>
    </div>
  );
};

export default OptionCard;
