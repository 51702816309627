import React, { useState } from "react";
import styles from "./Loading.module.css";
import Toast from "../Toast/Toast";

const quotes = [
  '"The only way to do great work is to love what you do." - Steve Jobs',
  '"Success is not final, failure is not fatal: It is the courage to continue that counts." - Winston Churchill',
  '"Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work." - Steve Jobs',
  '"The way to get started is to quit talking and begin doing." - Walt Disney',
  '"Don\'t be afraid to give up the good to go for the great." - John D. Rockefeller',
  '"Innovation distinguishes between a leader and a follower." - Steve Jobs',
  '"The only thing worse than starting something and failing... is not starting something." - Seth Godin',
  '"Your most unhappy customers are your greatest source of learning." - Bill Gates',
  "\"I think it's very important to have a feedback loop, where you're constantly thinking about what you've done and how you could be doing it better.\" - Elon Musk",
  "\"If you're not failing every now and again, it's a sign you're not doing anything very innovative.\" - Woody Allen",
  '"You don\'t have to be a genius or a visionary or even a college graduate to be successful. You just need a framework and a dream." - Michael Dell',
  '"The secret of change is to focus all of your energy, not on fighting the old, but on building the new." - Socrates',
  '"Life is not fair; get used to it." - Bill Gates,',
  '"Stay hungry, stay foolish." - Steve Jobs,',
  '"The people who are crazy enough to think they can change the world are the ones who do." - Steve Jobs',
  '"We become what we think about most of the time, and that\'s the strangest secret." - Earl Nightingale',
  '"The future belongs to those who believe in the beauty of their dreams." - Eleanor Roosevelt',
  '"The best time to plant a tree was 20 years ago. The second best time is now." - Chinese Proverb',
  '"Great things in business are never done by one person; they\'re done by a team of people." - Steve Jobs',
  '"The only limit to our realization of tomorrow will be our doubts of today." - Franklin D. Roosevelt',
  '"The most difficult thing is the decision to act, the rest is merely tenacity." - Amelia Earhart',
  '"The greatest glory in living lies not in never falling, but in rising every time we fall." - Nelson Mandela',
  '"It is our choices, that show what we truly are, far more than our abilities." - J.K. Rowling',
  '"You are never too old to set another goal or to dream a new dream." - C.S. Lewis',
  '"It does not matter how slowly you go as long as you do not stop." - Confucius',
  '"Believe you can and you\'re halfway there." - Theodore Roosevelt',
  '"Success usually comes to those who are too busy to be looking for it." - Henry David Thoreau',
  '"I am not afraid of storms, for I am learning how to sail my ship." - Louisa May Alcott',
  '"We are what we repeatedly do. Excellence, then, is not an act, but a habit." - Aristotle',
  '"Opportunities don\'t happen, you create them." - Chris Grosser',
  '"Our greatest weakness lies in giving up. The most certain way to succeed is always to try just one more time." - Thomas A. Edison',
  '"The harder I work, the luckier I get." - Samuel Goldwyn',
  '"Genius is one percent inspiration and ninety-nine percent perspiration." - Thomas Edison',
  '"Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful." - Albert Schweitzer',
  '"Don\'t worry about failure; you only have to be right once." - Drew Houston',
  '"The secret of getting ahead is getting started." - Mark Twain',
  '"You miss 100% of the shots you don\'t take." - Wayne Gretzky',
  '"When something is important enough, you do it even if the odds are not in your favor." - Elon Musk',
  '"Persistence is very important. You should not give up unless you are forced to give up." - Elon Musk',
  '"Some people don\'t like change, but you need to embrace change if the alternative is disaster." - Elon Musk',
  '"It\'s OK to have your eggs in one basket as long as you control what happens to that basket." - Elon Musk',
  '"I could either watch it happen or be a part of it." - Elon Musk',
  '"Failure is an option here. If things are not failing, you are not innovating enough." - Elon Musk',
  '"If something is important enough, even if the odds are against you, you should still do it." - Elon Musk',
  '"I think that\'s the single best piece of advice: constantly think about how you could be doing things better and questioning yourself." - Elon Musk',
  "\"You want to have a future where you're expecting things to be better, not one where you're expecting things to be worse.\" - Elon Musk",
  '"The journey of a thousand miles begins with one step." - Lao Tzu',
  '"Happiness depends upon ourselves." - Aristotle',
  "\"I have not failed. I've just found 10,000 ways that won't work.\" - Thomas Edison",
  '"Our greatest glory is not in never falling, but in rising every time we fall." - Confucius',
  '"Give me six hours to chop down a tree and I will spend the first four sharpening the axe." - Abraham Lincoln',
  '"The only true wisdom is in knowing you know nothing." - Socrates',
  '"It is the mark of an educated mind to be able to entertain a thought without accepting it." - Aristotle',
  '"Desires make slaves out of kings and patiences makes kings out of slaves" - Al-Ghazali',
  "“Knowledge without action is wastefulness and action without knowledge is foolishness.” - Al-Ghazali",
  '"To get what you love, you must first be patient with what you hate.” - Al-Ghazali',
  "“Do not allow your heart to take pleasure with the praises of people, nor be saddened by their condemnation.” - Al-Ghazali",
  "“Those who look for seashells will find seashells; those who open them will find pearls.” - Al-Ghazali",
  '"Well done is better than well said." - Benjamin Franklin',
  '"Be yourself; everyone else is already taken." - Oscar Wilde',
];

const Loading = () => {
  const generateRandomNumber = () => Math.floor(Math.random() * quotes.length);
  const [randomNum] = useState(generateRandomNumber());

  return (
    <div className={styles.loading}>
      <Toast />
      <div>{getRandomQuote(randomNum)}</div>
      <div className={styles.spinner}></div>
    </div>
  );
};

const getRandomQuote = (num) => {
  return <p className={styles.text}>{quotes[num]}</p>;
};

export default Loading;
