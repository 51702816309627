import React from "react";
import errorImage from "../../../assets/images/403_error.png";

const Error403 = () => {
  return (
    <div>
      <h1 style={{ textAlign: "center", color: "white" }}>
        Error 403: Access Forbidden
      </h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={errorImage}
          alt="403 error"
          style={{ width: "25rem", background: "white", borderRadius: "10px" }}
        />
      </div>
    </div>
  );
};

export default Error403;
