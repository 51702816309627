import styles from "./ApprovalCard.module.css";
import { useEffect, useState } from "react";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useFirebase } from "../../utils/contexts/FirebaseContext";
import { useLocation } from "react-router-dom";

const ApprovalCard = () => {
  const { db, functions } = useFirebase();
  const location = useLocation();
  const [comment, setComment] = useState("");
  const [clickedButton, setClickedButton] = useState("");

  useEffect(() => {
    const getComment = async () => {
      const candidateId = location.pathname.split("/")[2];

      // Get candidate profle
      const candidateProfilesDocRef = doc(db, "candidateProfiles", candidateId);
      const candidateProfileDocSnapshot = await getDoc(candidateProfilesDocRef);
      const candidateProfilesData = candidateProfileDocSnapshot.data();

      setComment(candidateProfilesData.statusMessage);
    };

    getComment();
  }, []);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const candidateId = location.pathname.split("/")[2];

      // Get candidate profle
      const candidateProfilesDocRef = doc(db, "candidateProfiles", candidateId);
      const candidateProfileDocSnapshot = await getDoc(candidateProfilesDocRef);
      const candidateProfilesData = candidateProfileDocSnapshot.data();

      // Get approved candidate
      const approvedCandidatesDocRef = doc(
        db,
        "approvedCandidates",
        candidateId
      );
      const approvedCandidatesDocSnapshot = await getDoc(
        approvedCandidatesDocRef
      );
      const approvedCandidateData = approvedCandidatesDocSnapshot.data();

      // Get approved candidate count
      const approvedCandidateCountDocRef = doc(
        db,
        "metadata",
        "approvedCandidateCount"
      );
      const approvedCandidateCountDocSnapshot = await getDoc(
        approvedCandidateCountDocRef
      );
      const approvedCandidateCountData =
        approvedCandidateCountDocSnapshot.data();

      if (clickedButton === "deny") {
        updateDoc(candidateProfilesDocRef, {
          status: "Denied",
          statusMessage: comment,
        });
      } else if (clickedButton === "approve") {
        updateDoc(candidateProfilesDocRef, {
          status: "Approved",
          statusMessage: comment,
        });

        // Call firebase function to copy approved profile picture to new approved candidates folder
        const approveProfilePicture = httpsCallable(
          functions,
          "approveProfilePicture"
        );

        try {
          await approveProfilePicture({ candidateId });
        } catch (error) {
          console.error("Error copying image:", error);
        }

        const approveResume = httpsCallable(functions, "approveResume");

        try {
          await approveResume({ candidateId });
        } catch (error) {
          console.error("Error copying image:", error);
        }

        if (approvedCandidateData?.id) {
          updateDoc(approvedCandidatesDocRef, {
            ...candidateProfilesData,
            status: "Approved",
            statusMessage: "",
          });
        } else {
          setDoc(approvedCandidatesDocRef, {
            ...candidateProfilesData,
            status: "Approved",
            statusMessage: "",
          });
          updateDoc(approvedCandidateCountDocRef, {
            count: approvedCandidateCountData.count + 1,
          });
        }
      }
    } catch (error) {}
  };

  return (
    <div className={styles.card}>
      <form onSubmit={handleSubmit}>
        <div className={styles.item}>
          <h2 className={styles.title}>Approve or deny candidate</h2>
        </div>
        <div className={styles.item}>
          <button
            type="submit"
            onClick={() => setClickedButton("approve")}
            className={styles.approveButton}
          >
            <img className={styles.icon} alt="Approve Candidate button" />{" "}
            Approve Candidate
          </button>
          <button
            type="submit"
            onClick={() => setClickedButton("deny")}
            className={styles.denyButton}
          >
            <img className={styles.icon} alt="Deny Candidate button" /> Deny
            Candidate
          </button>
        </div>
        <div className={styles.item}>
          <h2 className={styles.title}>Comments</h2>
        </div>

        <div className={styles.item}>
          <textarea
            rows="2"
            placeholder="Leave a comment here for changes that need to be made in order to get approved"
            value={comment}
            onChange={handleCommentChange}
            id={styles.summary}
          />
        </div>
      </form>
    </div>
  );
};

export default ApprovalCard;
