import OptionCard from "../../components/OptionCard/OptionCard";
import styles from "./Option.module.css";
import { Link } from "react-router-dom";
import useAuth from "../../utils/hooks/useAuth";
import Toast from "../../components/Toast/Toast";

const Option = () => {
  useAuth();

  return (
    <div>
      <Toast />
      <div>
        <h1 className={styles.title}>Who are you?</h1>
        <p
          style={{ color: "white", textAlign: "center", marginBottom: "5rem" }}
        >
          (Choose one)
        </p>
      </div>
      <div className={styles.container}>
        <Link
          to="/candidateprofile"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <OptionCard
            title={"I'm a candidate"}
            description={"I'm a candidate looking for a job!"}
          />
        </Link>
        <Link
          //to="/employerprofile"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <OptionCard
            //I'm hiring candidates
            title={"**Coming Soon**"}
            description={
              "I'm hiring full-time, part-time, and/or freelance candidates!"
            }
          />
        </Link>
      </div>
    </div>
  );
};

export default Option;
