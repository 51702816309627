import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFirebase } from "../contexts/FirebaseContext";
import axios from "axios";

const useAuth = () => {
  const { user, loading } = useFirebase();
  const navigate = useNavigate();
  const location = useLocation();
  const [access, setAccess] = useState("");

  useEffect(() => {
    if (user) {
      if (location.pathname === "/employerprofile") {
        const fetchData = async () => {
          try {
            await axios.post(
              "https://us-central1-nowhiteboarddevs.cloudfunctions.net/employerProfile",
              {
                userId: user.uid,
              }
            );

            setAccess("granted");
          } catch (error) {
            console.error("Error:", error);
            setAccess("denied");
          }
        };
        fetchData();
      } else if (location.pathname === "/candidateprofile") {
        const fetchData = async () => {
          try {
            await axios.post(
              "https://us-central1-nowhiteboarddevs.cloudfunctions.net/candidateProfile",
              {
                userId: user.uid,
              }
            );

            setAccess("granted");
          } catch (error) {
            console.error("Error:", error);
            setAccess("denied");
          }
        };
        fetchData();
      } else if (location.pathname.startsWith("/pendingApproval")) {
        const fetchData = async () => {
          try {
            await axios.post(
              "https://us-central1-nowhiteboarddevs.cloudfunctions.net/pendingApproval",
              {
                userId: user.uid,
              }
            );

            setAccess("granted");
          } catch (error) {
            console.error("Error:", error);
            setAccess("denied");
          }
        };
        fetchData();
      }
    }
  }, [location.pathname, navigate, user]);


  if (loading) {
    return "Loading";
  } else if (access === "denied" || !user) {
    return "Not authorized";
  } else if (access === "granted") {
    return user;
  }
};

export default useAuth;
