import CandidateCard from "../../components/CandidateCard/CandidateCard";
import styles from "./PendingApproval.module.css";
import { useFirebase } from "../../utils/contexts/FirebaseContext";
import { collection, getDocs, query, where, or } from "firebase/firestore";
import { useEffect, useState } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import useAuth from "../../utils/hooks/useAuth";
import Loading from "../../components/Loading/Loading";
import Error403 from "../../components/Errors/403/Error403";

const PendingApproval = () => {
  const { db, user, loading, storage } = useFirebase();
  const [candidates, setCandidates] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const validation = useAuth();

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const candidatesCollectionRef = collection(db, "candidateProfiles");
        const candidatesQuery = query(
          candidatesCollectionRef,
          or(
            where("status", "==", "Pending update"),
            where("status", "==", "Pending creation")
          )
        );
        const candidatesSnapshot = await getDocs(candidatesQuery);
        const candidates = candidatesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCandidates(candidates);
        candidates.forEach((candidate) => {
          if (!imageUrls[candidate.id] && candidate.hasProfilePic) {
            fetchImage(candidate.id);
          }
        });
      } catch (error) {
        console.error("Error fetching candidate data");
      }
    };

    const fetchImage = async (userId) => {
      try {
        const storageRef = ref(
          storage,
          `candidateProfiles/${userId}/images/${userId}`
        );

        const url = await getDownloadURL(storageRef);
        setImageUrls((prevImageUrls) => ({
          ...prevImageUrls,
          [userId]: url,
        }));
      } catch (error) {
        console.error(`Error fetching images`);
      }
    };

    if (
      validation &&
      validation !== "Not authorized" &&
      validation !== "Loading"
    ) {
      fetchCandidates();
    }
  }, [validation]);

  if (!validation || validation === "Loading") {
    return <Loading />;
  } else if (validation === "Not authorized") {
    return <Error403 />;
  } else {
    return (
      <div>
        {candidates.map((candidate) => (
          <CandidateCard
            key={candidate.id}
            candidate={candidate}
            hasResume={candidate.hasResume}
            status="Pending approval"
            imageUrl={imageUrls[candidate.id]}
          />
        ))}
      </div>
    );
  }
};

export default PendingApproval;
