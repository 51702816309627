import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Pagination.module.css";
import rightIcon from "../../assets/images/arrow_next_right_icon.png";
import leftIcon from "../../assets/images/arrow_back_left_icon.png";

const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  authorized,
}) => {
  const location = useLocation();
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageShiftAmount = 10;
  const pageCount = pageShiftAmount > totalPages ? totalPages : pageShiftAmount;
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(pageCount - 1);

  useEffect(() => {
    // Calculate the new start index based on the current page
    const newStartIndex = Math.max(1, currentPage - (pageCount - 1));

    // Calculate the new end index based on the start index and page count
    const newEndIndex = Math.min(totalPages, newStartIndex + pageCount - 1);

    // Update the start and end index
    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);

    // Use location.pathname to make page move with url?
    // Add 'currentPage', 'pageCount', and 'totalPages'?
  }, [location.pathname, currentPage, pageCount, totalPages]);

  const handleShiftRight = () => {
    const newStartIndex = startIndex + pageCount;
    const newEndIndex = endIndex + pageCount;

    if (newEndIndex < totalPages) {
      setStartIndex(newStartIndex);
      setEndIndex(newEndIndex);
    } else {
      setStartIndex(totalPages - (pageCount - 1));
      setEndIndex(totalPages);
    }
  };
  const handleShiftLeft = () => {
    const newStartIndex = startIndex - pageCount;
    const newEndIndex = endIndex - pageCount;

    if (newStartIndex > 0) {
      setStartIndex(newStartIndex);
      setEndIndex(newEndIndex);
    } else {
      setStartIndex(1);
      setEndIndex(pageCount);
    }
  };

  const generatePageLinks = () => {
    const links = [];

    for (let i = startIndex; i <= endIndex; i++) {
      links.push(
        authorized || i === 1 ? (
          <Link
            key={i}
            to={`?page=${i}`}
            className={i === currentPage ? styles.active : ""}
            onClick={() => {
              onPageChange(i);
              window.scrollTo({ top: 0, behavior: "instant" });
            }}
          >
            {i}
          </Link>
        ) : (
          <Link
            key={i}
            className={i === currentPage ? styles.active : ""}
            onClick={() => {
              alert(
                `If you'd like to see more profiles, create a company account.`
              );
            }}
          >
            {i}
          </Link>
        )
      );
    }

    return links;
  };

  return (
    <div className={styles.pagination}>
      {startIndex > 1 ? (
        <button onClick={handleShiftLeft}>
          <img src={leftIcon} className={styles.icon} alt="" />
        </button>
      ) : (
        ""
      )}
      {generatePageLinks()}
      {endIndex < totalPages ? (
        <button onClick={handleShiftRight}>
          <img src={rightIcon} className={styles.icon} alt="" />
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default Pagination;
