import styles from "./CandidateDetailsCard.module.css";
import pic from "../../assets/images/profile_pic.png";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import { useFirebase } from "../../utils/contexts/FirebaseContext";
import { getDoc, doc } from "firebase/firestore";
import Error404 from "../Errors/404/Error404";
import BackButton from "../BackButton/BackButton";
import briefcaseIcon from "../../assets/images/briefcase_icon.png";

const CandidateDetailsCard = () => {
  const { storage, db, loading } = useFirebase();
  //const authHome = useAuthHome();
  const location = useLocation();
  const { candidate, imageUrl, page, status } = location.state || {};
  const [resumeUrl, setResumeUrl] = useState(null);
  const [error, setError] = useState(false);
  const [cachedCandidate, setCachedCandidate] = useState(candidate);
  const [cachedImageUrl, setCachedImageUrl] = useState(imageUrl);

  useEffect(() => {
    const url = location.pathname.split("/");
    const fetchResume = async (userId) => {
      try {
        let folder = "approvedCandidates";
        if (status === "Pending approval") {
          folder = "candidateProfiles";
        }
        const storageRef = ref(
          storage,
          `${folder}/${userId}/resumes/${userId}`
        );

        const url = await getDownloadURL(storageRef);
        setResumeUrl(url);
      } catch (error) {
        console.error("Error fetching resume: ", error);
      }
    };

    const fetchData = async () => {
      try {
        const candidateRef = doc(
          db,
          `approvedCandidates/${url[url.length - 1]}`
        );
        const candidateSnapshot = await getDoc(candidateRef);

        setCachedCandidate(candidateSnapshot.data());

        if (candidateSnapshot.data()?.hasProfilePic) {
          fetchImage(candidateSnapshot.data().id);
        }

        if (candidateSnapshot.data()?.hasResume) {
          fetchResume(candidateSnapshot.data().id);
        }

      } catch (error) {
        console.error("Error fetching candidate data: ", error);
        setError(true);
      }
    };

    const fetchImage = async (userId) => {
      try {
        const storageRef = ref(
          storage,
          `approvedCandidates/${userId}/images/${userId}`
        );

        const url = await getDownloadURL(storageRef);
        setCachedImageUrl(url);
      } catch (error) {
        console.error(`Error fetching images`);
      }
    };

    // && !cachedCandidate
    if (!candidate && !loading) {
      fetchData();
    }
    if (candidate && candidate.hasResume) {
      fetchResume(candidate.id);
    }
  }, [db, storage, location, candidate, page, loading]);

  if (error) {
    return <Error404 />;
  }

  return (
    <div className={styles.card}>
      <BackButton
        path={
          page
            ? `/?page=${page}`
            : `/?page=${Math.floor(cachedCandidate?.index / 10) + 1}`
        }
      />
      <div className={styles.jobTitle}>
        <h2 className={styles.title}>{cachedCandidate?.title}</h2>
      </div>
      <div className={styles.imageItem}>
        {imageUrl || cachedImageUrl ? (
          <img
            src={cachedImageUrl ? cachedImageUrl : imageUrl}
            alt="Profile Pic"
            id={styles.profile}
          />
        ) : (
          <img id={styles.profile} src={pic} alt="" />
        )}
      </div>
      <div className={styles.item}>
        <div className={styles.label}>
          <p>{cachedCandidate?.firstName}</p>
        </div>

        <div className={styles.label}>
          <p>{cachedCandidate?.lastName}</p>
        </div>
      </div>
      <p>
        <b>Featured Summary:</b>
      </p>
      <div className={styles.item}>
        <p className={styles.input}>{cachedCandidate?.featuredSummary}</p>
      </div>
      <p>
        <b>Summary:</b>
      </p>
      <div className={styles.item}>
        <p className={styles.input}>{cachedCandidate?.summary}</p>
      </div>
      <p>
        <b>Search Status:</b>
      </p>
      <div className={styles.skills}>
        <Select
          defaultValue={cachedCandidate?.searchStatus}
          isSearchable={false}
          isDisabled
          placeholder=""
        />
      </div>
      <p>
        <b>Job Type:</b>
      </p>
      <div className={styles.skills}>
        <Select
          isMulti
          defaultValue={cachedCandidate?.jobType}
          isSearchable={false}
          isDisabled
          placeholder=""
        />
      </div>
      <p>
        <b>Job Level:</b>
      </p>
      <div className={styles.skills}>
        <Select
          isMulti
          defaultValue={cachedCandidate?.jobLevel}
          isSearchable={false}
          isDisabled
          placeholder=""
        />
      </div>
      <p>
        <b>Time Zone:</b>
      </p>
      <div className={styles.skills}>
        <Select
          defaultValue={cachedCandidate?.timeZone}
          isSearchable={false}
          isDisabled
          placeholder=""
        />
      </div>
      <p>
        <b>Skills:</b>
      </p>
      <div className={styles.skills}>
        <Select
          defaultValue={cachedCandidate?.skills}
          isSearchable={false}
          isMulti
          isDisabled
        />
      </div>
      <p>
        <b>Contact info: </b>
      </p>
      <div>
        <p>
          {cachedCandidate?.email ? (
            cachedCandidate?.email
          ) : (
            <button className={styles.contactButton}>
              <img
                src={briefcaseIcon}
                className={styles.icon}
                alt="Contact Candidate button"
              />{" "}
              Contact Candidate
            </button>
          )}
        </p>
      </div>
      {resumeUrl && (
        <p>
          <b>Resume:</b>
          <br /> <br />
          <a href={resumeUrl} target="_blank" rel="noreferrer" download>
            View Resume
          </a>
        </p>
      )}
    </div>
  );
};

export default CandidateDetailsCard;
