import React from "react";
import styles from "./CandidateCard.module.css";
import pic from "../../assets/images/profile_pic.png";
import { useNavigate } from "react-router-dom";

const CandidateCard = (props) => {
  const navigate = useNavigate();
  const { candidate, imageUrl, page, status } = props;
  const maxLength = 200;
  const truncatedSummary =
    candidate.featuredSummary.length > maxLength
      ? candidate.featuredSummary.slice(0, maxLength) + "..."
      : candidate.featuredSummary;
  return (
    <div className={styles.container}>
      <div
        className={styles.card}
        onClick={() => {
          status === "Pending approval"
            ? navigate(`/pendingApproval/${candidate.id}`, {
                state: { candidate, imageUrl, page, status },
              })
            : navigate(`/candidate/${candidate.id}`, {
                state: { candidate, imageUrl, page, status },
              });
          window.scrollTo({ top: 0, behavior: "instant" });
        }}
      >
        {imageUrl ? (
          <img
            className={styles.profile}
            src={imageUrl}
            alt="Selected"
            style={{ cursor: "pointer" }}
          />
        ) : (
          <img className={styles.profile} src={pic} alt="Profile Pic" />
        )}

        <div>
          <h4 className={styles.jobTitle}>
            {candidate.firstName || candidate.lastName
              ? candidate.firstName + " " + candidate.lastName + " - "
              : ""}
            {candidate.title}
          </h4>

          <p className={styles.truncatedSummary}>{truncatedSummary}</p>
        </div>
      </div>
    </div>
  );
};

export default CandidateCard;
