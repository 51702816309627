import SignUpCard from "../../components/SignUpCard/SignUpCard";
import styles from "./SignUp.module.css";
import { Link } from "react-router-dom";

const SignUp = () => {
  return (
    <div>
      <h1 className={styles.title}>Create account</h1>
      <p className={styles.accountText}>
        Already have an account?
        <Link to="/signin" className={styles.link}>
          {" "}
          Click Here
        </Link>
      </p>
      <div className={styles.container}>
        <SignUpCard />
      </div>
    </div>
  );
};

export default SignUp;
