import { useEffect, useState } from "react";
import styles from "./CandidateProfileCard.module.css";
import pic from "../../assets/images/profile_pic.png";
import Select from "react-select";
import { useFirebase } from "../../utils/contexts/FirebaseContext";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import BackButton from "../BackButton/BackButton";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

const CandidateProfileCard = () => {
  const { storage, user, db, loading } = useFirebase();
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [featuredSummary, setFeaturedSummary] = useState("");
  const [summary, setSummary] = useState("");
  const [resume, setResume] = useState(null);
  const [resumeUrl, setResumeUrl] = useState(null);
  const [resumeError, setResumeError] = useState("");
  const [skills, setSkills] = useState([]);
  const [timeZone, setTimeZone] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [jobType, setJobType] = useState("");
  const [jobLevel, setJobLevel] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageError, setImageError] = useState("");
  const [status, setStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const maxFeaturedSummaryChars = 200;
  const maxSummaryChars = 1000;

  useEffect(() => {
    const fetchImage = async (userId) => {
      try {
        const storageRef = ref(
          storage,
          `candidateProfiles/${userId}/images/${userId}`
        );

        const url = await getDownloadURL(storageRef);
        setImageUrl(url);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };
    const fetchData = async () => {
      try {
        if (user) {
          const usersQuery = query(
            collection(db, "candidateProfiles"),
            where("id", "==", user.uid)
          );

          const usersSnapshot = await getDocs(usersQuery);

          if (usersSnapshot.size > 0) {
            const userData = usersSnapshot.docs[0].data();
            if (userData?.hasProfilePic) {
              fetchImage(userData.id);
            }
            if (userData?.hasResume) {
              fetchResume(userData.id);
            }
            const {
              title,
              firstName,
              lastName,
              featuredSummary,
              summary,
              skills,
              timeZone,
              jobLevel,
              jobType,
              searchStatus,
            } = userData;
            setTitle(title);
            setFirstName(firstName);
            setLastName(lastName);
            setFeaturedSummary(featuredSummary);
            setSummary(summary);
            setSkills(skills);
            setTimeZone(timeZone);
            setJobLevel(jobLevel);
            setJobType(jobType);
            setSearchStatus(searchStatus);
            setStatus(userData.status);
            setStatusMessage(userData.statusMessage);
          } else {
            setStatus("Empty");
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    const fetchResume = async (userId) => {
      try {
        const storageRef = ref(
          storage,
          `candidateProfiles/${userId}/resumes/${userId}`
        );

        const url = await getDownloadURL(storageRef);
        setResumeUrl(url);
      } catch (error) {
        console.error("Error fetching resume: ", error);
      }
    };
    if (!loading) {
      fetchData();
    }
  }, [storage, user, db, loading]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const imageMimeTypes = ["image/jpeg", "image/png"];

    const maxSize = 5 * 1024 * 1024; //5MB

    if (file && !imageMimeTypes.includes(file.type)) {
      setImageError("Invalid file type. Please choose a jpg/jpeg or png file.");
      setTimeout(() => {
        setImageError("");
      }, 3000);
    } else if (file && file.size > maxSize) {
      setImageError(
        "Image size exceeds the limit (5MB). Please choose a smaller image"
      );
      setTimeout(() => {
        setImageError("");
      }, 3000);
    } else {
      setSelectedImage(file);
      setImageError("");
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastName = (event) => {
    setLastName(event.target.value);
  };

  const handleFeaturedSummaryChange = (event) => {
    const inputText = event.target.value;

    if (inputText.length <= maxFeaturedSummaryChars) {
      setFeaturedSummary(event.target.value);
    }
  };

  const handleSummaryChange = (event) => {
    const inputText = event.target.value;

    if (inputText.length <= maxSummaryChars) {
      setSummary(event.target.value);
    }
  };

  const handleResumeChange = (event) => {
    const file = event.target.files[0];

    const maxSize = 2 * 1024 * 1024; //5MB

    if (file && file.type !== "application/pdf") {
      setResumeError("Invalid file type. Please choose a PDF file.");
      setTimeout(() => {
        setResumeError("");
      }, 3000);
    } else if (file && file.size > maxSize) {
      setResumeError(
        "File size exceeds 2MB. Please choose a smaller PDF file."
      );
      setTimeout(() => {
        setResumeError("");
      }, 3000);
    } else {
      setResume(file);
      setResumeError("");
    }
  };

  const handleSkillChange = (event) => {
    setSkills(event);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (user.emailVerified) {
      try {
        const candidateProfilesDocRef = doc(db, "candidateProfiles", user.uid);
        const candidateProfilesDocSnapshot = await getDoc(
          candidateProfilesDocRef
        );
        const candidateProfilesData = candidateProfilesDocSnapshot.data();

        if (candidateProfilesData?.id) {
          setStatus("Pending update");
          updateDoc(candidateProfilesDocRef, {
            title,
            firstName,
            lastName,
            featuredSummary,
            summary,
            skills,
            hasProfilePic:
              candidateProfilesData?.hasProfilePic ||
              (selectedImage && !imageError),
            hasResume:
              candidateProfilesData?.hasResume || (resume && !resumeError),
            status: "Pending update",
            timeZone,
            jobLevel,
            jobType,
            searchStatus,
          });
        } else {
          const candidateProfileCountRef = doc(
            db,
            "metadata",
            "candidateProfileCount"
          );
          const candidateProfileCountSnapshot = await getDoc(
            candidateProfileCountRef
          );
          const candidateProfileCount =
            candidateProfileCountSnapshot.data().count;

          setStatus("Pending creation");

          setDoc(candidateProfilesDocRef, {
            index: candidateProfileCount,
            id: user.uid,
            email: user.email,
            title,
            firstName,
            lastName,
            featuredSummary,
            summary,
            skills,
            hasProfilePic:
              candidateProfilesData?.hasProfilePic ||
              (selectedImage && !imageError),
            hasResume:
              candidateProfilesData?.hasResume || (resume && !resumeError),
            status: "Pending creation",
            statusMessage: "",
            timeZone,
            jobLevel,
            jobType,
            searchStatus,
          });

          updateDoc(candidateProfileCountRef, {
            count: candidateProfileCount + 1,
          });
        }

        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) {
            const userDocData = userDocSnapshot.data();
            if (userDocData.role === "empty") {
              updateDoc(userDocRef, { role: "candidate" });
            }
          }
        }

        if (selectedImage) {
          const storageRef = ref(
            storage,
            `candidateProfiles/${user.uid}/images/${user.uid}`
          );

          await uploadBytes(storageRef, selectedImage);

          await getDownloadURL(storageRef);
        }

        if (resume) {
          const storageRef = ref(
            storage,
            `candidateProfiles/${user.uid}/resumes/${user.uid}`
          );

          await uploadBytes(storageRef, resume);

          const downloadURL = await getDownloadURL(storageRef);

          setResumeUrl(downloadURL);
        }

      } catch (error) {
        console.error("Error uploading file", error);
      }
    } else {
      localStorage.setItem("toastType", "bad");
      localStorage.setItem(
        "toastMessage",
        "A verification link was previously sent to your email. Please verifiy your email so that your account can be viewed by companies. You may need to log out and back in if you have already verified your email."
      );
      console.error("Cannot upload, email is not verified.");
      window.dispatchEvent(new Event("storage"));
    }
  };

  const totalSkills = [
    "JavaScript",
    "Python",
    "Java",
    "C#",
    "C++",
    "Ruby",
    "Swift",
    "Go",
    "Rust",
    "TypeScript",
    "PHP",
    "Kotlin",
    "HTML/CSS",
    "React",
    "Angular",
    "Vue.js",
    "Node.js",
    "Express.js",
    "Ruby on Rails",
    "Django",
    "Spring",
    ".NET Core",
    "Flask",
    "Bootstrap",
    "TensorFlow",
    "R",
    "Perl",
    "Scala",
    "Elixir",
    "Haskell",
    "Lua",
    "Erlang",
    "Groovy",
    "Dart",
    "Clojure",
    "Ember.js",
    "Sass (CSS Preprocessor)",
    "LESS (CSS Preprocessor)",
    "jQuery",
    "Laravel",
    "Symfony",
    "Spring Boot",
    "Gatsby",
    "Vuepress",
    "Redux",
    "GraphQL",
    "Pandas",
    "NumPy",
    "Keras",
    "PyTorch",
    "React Native",
    "Flutter",
    "Unity",
    "MongoDB",
    "PostgreSQL",
    "SQLite",
    "MySQL",
    "Redis",
    "AWS (Amazon Web Services)",
    "Docker",
    "Kubernetes",
    "Git",
    "GitHub",
    "GitLab",
    "Visual Studio Code",
    "Sublime Text",
    "Eclipse",
    "IntelliJ IDEA",
    "PyCharm",
    "NetBeans",
    "Android Studio",
    "Xcode",
    "PHPStorm",
    "WebStorm",
    "Atom",
    "RubyMine",
    "Machine Learning",
    "Deep Learning",
    "Artificial Intelligence",
    "Data Science",
    "Big Data",
    "Blockchain",
    "Serverless",
    "API",
    "GraphQL",
    "RESTful API",
    "OAuth",
    "Authentication",
    "Authorization",
    "OAuth2",
    "JSON",
    "XML",
    "YAML",
    "Cassandra",
    "Hadoop",
    "Spark",
    "Elasticsearch",
    "RabbitMQ",
    "Kafka",
    "Zookeeper",
    "Nginx",
    "Apache",
    "Jenkins",
    "Travis CI",
    "CircleCI",
    "Heroku",
    "Azure",
    "Google Cloud",
    "IBM Cloud",
    "NPM",
    "Yarn",
    "Webpack",
    "Babel",
    "Gulp",
    "Grunt",
    "Jest",
    "Mocha",
    "Cypress",
    "Chai",
    "Selenium",
    "Cucumber",
    "Jira",
    "Trello",
    "Slack",
    "Confluence",
    "Asana",
  ];

  const skillOptions = totalSkills.map((skill) => ({
    value: skill,
    label: skill,
  }));

  const handleTimeZone = (event) => {
    setTimeZone(event);
  };

  const cityTimezones = [
    { city: "Honolulu", timezone: "Pacific/Honolulu" }, // GMT-10:00
    { city: "Anchorage", timezone: "America/Anchorage" }, // GMT-09:00
    { city: "Los Angeles", timezone: "America/Los_Angeles" }, // GMT-08:00
    { city: "Denver", timezone: "America/Denver" }, // GMT-07:00
    { city: "Chicago", timezone: "America/Chicago" }, // GMT-06:00
    { city: "Mexico City", timezone: "America/Mexico_City" }, // GMT-06:00
    { city: "New York", timezone: "America/New_York" }, // GMT-05:00
    { city: "Toronto", timezone: "America/Toronto" }, // GMT-05:00
    { city: "Lima", timezone: "America/Lima" }, // GMT-05:00
    { city: "Bogotá", timezone: "America/Bogota" }, // GMT-05:00
    { city: "Sao Paulo", timezone: "America/Sao_Paulo" }, // GMT-03:00
    { city: "Buenos Aires", timezone: "America/Argentina/Buenos_Aires" }, // GMT-03:00
    { city: "St. John's", timezone: "America/St_Johns" }, // GMT-03:30
    { city: "Reykjavik", timezone: "Atlantic/Reykjavik" }, // GMT+00:00
    { city: "Nouakchott", timezone: "Africa/Nouakchott" }, // GMT+00:00
    { city: "London", timezone: "Europe/London" }, // GMT+00:00
    { city: "Lagos", timezone: "Africa/Lagos" }, // GMT+01:00
    { city: "Berlin", timezone: "Europe/Berlin" }, // GMT+01:00
    { city: "Cape Town", timezone: "Africa/Johannesburg" }, // GMT+02:00
    { city: "Cairo", timezone: "Africa/Cairo" }, // GMT+02:00
    { city: "Moscow", timezone: "Europe/Moscow" }, // GMT+03:00
    { city: "Nairobi", timezone: "Africa/Nairobi" }, // GMT+03:00
    { city: "Dubai", timezone: "Asia/Dubai" }, // GMT+04:00
    { city: "Karachi", timezone: "Asia/Karachi" }, // GMT+05:00
    { city: "Delhi", timezone: "Asia/Kolkata" }, // GMT+05:30
    { city: "Kathmandu", timezone: "Asia/Kathmandu" }, // GMT+05:45
    { city: "Dhaka", timezone: "Asia/Dhaka" }, // GMT+06:00
    { city: "Yangon", timezone: "Asia/Yangon" }, // GMT+06:30
    { city: "Bangkok", timezone: "Asia/Bangkok" }, // GMT+07:00
    { city: "Jakarta", timezone: "Asia/Jakarta" }, // GMT+07:00
    { city: "Beijing", timezone: "Asia/Shanghai" }, // GMT+08:00
    { city: "Perth", timezone: "Australia/Perth" }, // GMT+08:00
    { city: "Seoul", timezone: "Asia/Seoul" }, // GMT+09:00
    { city: "Tokyo", timezone: "Asia/Tokyo" }, // GMT+09:00
    { city: "Darwin", timezone: "Australia/Darwin" }, // GMT+09:30
    { city: "Adelaide", timezone: "Australia/Adelaide" }, // GMT+09:30
    { city: "Brisbane", timezone: "Australia/Brisbane" }, // GMT+10:00
    { city: "Sydney", timezone: "Australia/Sydney" }, // GMT+10:00
    { city: "Honiara", timezone: "Pacific/Guadalcanal" }, // GMT+11:00
    { city: "Port Moresby", timezone: "Pacific/Port_Moresby" }, // GMT+10:00
    { city: "Fiji", timezone: "Pacific/Fiji" }, // GMT+12:00
    { city: "Auckland", timezone: "Pacific/Auckland" }, // GMT+12:00
    { city: "Chatham Islands", timezone: "Pacific/Chatham" }, // GMT+12:45
    { city: "Apia", timezone: "Pacific/Apia" }, // GMT+13:00
    { city: "Tongatapu", timezone: "Pacific/Tongatapu" }, // GMT+13:00
  ];

  const getTimeZone = (city) => {
    const timeZone = cityTimezones.find((item) => item.city === city).timezone;


    if (!timeZone) {
      console.error(`Timezone information for ${city} not available.`);
      return null;
    }

    const now = new Date();
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: timeZone,
      timeZoneName: "short",
    });

    const parts = formatter.formatToParts(now);
    const timeZonePart = parts.find((part) => part.type === "timeZoneName");

    if (!timeZonePart) {
      console.error(`Unable to determine timezone for ${city}`);
      return null;
    }

    return timeZonePart.value;
  };

  const gmtTimeZones = [];

  cityTimezones.forEach((item) => {
    const timeZone = getTimeZone(item.city);

    if (timeZone !== null) {
      gmtTimeZones.push([item.city, timeZone]);
    }
  });

  const timeZoneOptions = gmtTimeZones.map((item) => ({
    value: item,
    label: item[0] + " - " + item[1],
  }));

  const jobLevels = [
    "Junior/Entry-level",
    "Mid",
    "Senior",
    "Staff",
    "Principal",
    "C-Suite",
  ];

  const jobLevelsOptions = jobLevels.map((level) => ({
    value: level,
    label: level,
  }));

  const handleJobLevel = (event) => {
    setJobLevel(event);
  };

  const jobTypes = ["Part-time", "Full-time", "Internship", "Contract"];

  const jobTypesOptions = jobTypes.map((type) => ({
    value: type,
    label: type,
  }));

  const handleJobType = (event) => {
    setJobType(event);
  };

  const searchingStatus = [
    "Actively Searching",
    "Open to Opportunities",
    "Not Open to Opportunities",
  ];

  const searchStatusOptions = searchingStatus.map((status) => ({
    value: status,
    label: status,
  }));

  const handleSearchStatus = (event) => {
    setSearchStatus(event);
  };

  return (
    <div className={styles.card}>
      <form onSubmit={handleSubmit}>
        <BackButton path={"/"} />

        {user.emailVerified ? (
          status === "" || !status ? (
            ""
          ) : status === "Empty" ? (
            <div className={styles.statusAlign}>
              <p className={styles.statusGrey}>
                You haven't filled your profile out yet. Fill out and save your
                profile to make it viewable by companies.{" "}
              </p>
            </div>
          ) : status === "Pending creation" ? (
            <div className={styles.statusAlign}>
              <p className={styles.statusOrange}>
                Your new profile is pending approval. It will be viewable by
                companies once it is approved. We manually review each profile
                to ensure high quality.
              </p>
            </div>
          ) : status === "Pending update" ? (
            <div className={styles.statusAlign}>
              <p className={styles.statusOrange}>
                The update you made to your profile is pending approval. It will
                be viewable by companies once it is approved. We manually review
                each profile to ensure high quality.
              </p>
            </div>
          ) : status === "Denied" ? (
            <div className={styles.statusAlign}>
              <p className={styles.statusRed}>
                Your profile has some changes that need to be made before it can
                go live and be viewable by companies. Please make the suggested
                changes and re-submit for review. <br />
                <br /> Suggested Changes: <br /> <br /> "{statusMessage}"
              </p>
            </div>
          ) : (
            <div className={styles.statusAlign}>
              <p className={styles.statusGreen}>
                Your profile is live. All companies can see it!{" "}
              </p>
            </div>
          )
        ) : (
          <div className={styles.statusAlign}>
            <p className={styles.statusRed}>
              Status: Pending email verification. Verify your email to create a
              profile.
            </p>
          </div>
        )}

        <div className={styles.item}>
          {user.emailVerified ? (
            <input
              type="text"
              placeholder="Title (Ex: React Dev)"
              value={title}
              onChange={handleTitleChange}
              required
              className={styles.jobTitle}
            />
          ) : (
            <input
              type="text"
              placeholder="Title (Ex: React Dev)"
              className={styles.jobTitle}
              disabled
            />
          )}
        </div>
        {user.emailVerified ? (
          <div className={styles.imageItem}>
            <input
              type="file"
              id="imageInput"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <label htmlFor="imageInput">
              {selectedImage ? (
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected"
                  style={{ cursor: "pointer" }}
                  id={styles.profile}
                />
              ) : imageUrl ? (
                <img
                  src={imageUrl}
                  alt="Selected"
                  style={{ cursor: "pointer" }}
                  id={styles.profile}
                />
              ) : (
                <img
                  src={pic}
                  alt="Selected"
                  style={{ cursor: "pointer" }}
                  id={styles.profile}
                />
              )}
              <p style={{ fontSize: "10px" }}>Image size cannot exceed 5 MB</p>
              {imageError && <p style={{ color: "red" }}>{imageError}</p>}
            </label>
          </div>
        ) : (
          <div className={styles.imageItem}>
            <label htmlFor="imageInput">
              <img
                src={pic}
                alt="Profile picture placeholder"
                id={styles.profile}
              />
              <p style={{ fontSize: "10px" }}>Image size cannot exceed 5 MB</p>
            </label>
          </div>
        )}

        {user.emailVerified ? (
          <div className={styles.item}>
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={handleFirstNameChange}
              required
              className={styles.input}
            />
            <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={handleLastName}
              required
              className={styles.input}
            />
          </div>
        ) : (
          <div className={styles.item}>
            <input
              type="text"
              placeholder="First Name"
              className={styles.input}
              disabled
            />
            <input
              type="text"
              placeholder="Last Name"
              className={styles.input}
              disabled
            />
          </div>
        )}

        {user.emailVerified ? (
          <div>
            <textarea
              rows="2"
              placeholder="Featured Summary (This will display on the front page, do not add personal info here - add quick things that will attract employers like credentials, years of experience, area of expertise, degrees, etc)"
              value={featuredSummary}
              onChange={handleFeaturedSummaryChange}
              required
              id={styles.summary}
            />
            <p id={styles.featuredSummaryCounter}>
              {featuredSummary.length}/{maxFeaturedSummaryChars}
            </p>
          </div>
        ) : (
          <div>
            <textarea
              rows="2"
              placeholder="Featured Summary (This will display on the front page, do not add personal info here - add quick things that will attract employers like credentials, years of experience, area of expertise, degrees, etc)"
              id={styles.summary}
              disabled
            />
            <p id={styles.featuredSummaryCounter}>
              {featuredSummary.length}/{maxFeaturedSummaryChars}
            </p>
          </div>
        )}

        {user.emailVerified ? (
          <div>
            <textarea
              rows="10"
              placeholder="Summary, feel free to add personal info here - You've got an employer's attention, now show them why they should hire you. Add your story here and whatever you want the employer to know!"
              value={summary}
              onChange={handleSummaryChange}
              required
              id={styles.summary}
            />
            <p id={styles.featuredSummaryCounter}>
              {summary.length}/{maxSummaryChars}
            </p>
          </div>
        ) : (
          <div>
            <textarea
              rows="10"
              placeholder="Summary, feel free to add personal info here - You've got an employer's attention, now show them why they should hire you. Add your story here and whatever you want the employer to know!"
              disabled
              id={styles.summary}
            />
            <p id={styles.featuredSummaryCounter}>
              {summary.length}/{maxSummaryChars}
            </p>
          </div>
        )}

        {user.emailVerified ? (
          <div>
            <p>Search Status: </p>
            <div className={styles.skills}>
              <Select
                options={searchStatusOptions}
                value={searchStatus}
                onChange={handleSearchStatus}
                placeholder="Select your search status..."
                required
              />
            </div>
          </div>
        ) : (
          <div>
            <p>Search Status:</p>
            <div className={styles.skills}>
              <Select placeholder="Select your search status..." isDisabled />
            </div>
          </div>
        )}

        {user.emailVerified ? (
          <div>
            <p>Job Type: </p>
            <div className={styles.skills}>
              <Select
                isMulti
                options={jobTypesOptions}
                value={jobType}
                onChange={handleJobType}
                placeholder="Select Type of Job You're Interested In..."
                required
              />
            </div>
          </div>
        ) : (
          <div>
            <p>Job Type:</p>
            <div className={styles.skills}>
              <Select
                isMulti
                placeholder="Select Type of Job You're Interested In..."
                isDisabled
              />
            </div>
          </div>
        )}

        {user.emailVerified ? (
          <div>
            <p>Job Level: </p>
            <div className={styles.skills}>
              <Select
                isMulti
                options={jobLevelsOptions}
                value={jobLevel}
                onChange={handleJobLevel}
                placeholder="Select Job Levels You're Interested In..."
                required
              />
            </div>
          </div>
        ) : (
          <div>
            <p>Job Level:</p>
            <div className={styles.skills}>
              <Select isMulti placeholder="Select Job Level..." isDisabled />
            </div>
          </div>
        )}

        {user.emailVerified ? (
          <div>
            <p>Timezone: </p>
            <div className={styles.skills}>
              <Select
                options={timeZoneOptions}
                value={timeZone}
                onChange={handleTimeZone}
                placeholder="Select Time Zone..."
                required
              />
            </div>
          </div>
        ) : (
          <div>
            <p>Timezone:</p>
            <div className={styles.skills}>
              <Select placeholder="Select Time Zone..." isDisabled />
            </div>
          </div>
        )}

        {user.emailVerified ? (
          <div>
            <p>Skills:</p>
            <div className={styles.skills}>
              <Select
                isMulti
                options={skillOptions}
                value={skills}
                onChange={handleSkillChange}
                placeholder="Select skills..."
              />
            </div>
          </div>
        ) : (
          <div>
            <p>Skills:</p>
            <div className={styles.skills}>
              <Select isMulti placeholder="Select skills..." isDisabled />
            </div>
          </div>
        )}

        {user.emailVerified ? (
          <div>
            <p>Resume:</p>
            <p style={{ fontSize: "10px" }}>
              Resume must be in pdf format and cannot exceed 2 MB.
            </p>
            <input
              type="file"
              accept=".pdf"
              onChange={handleResumeChange}
              className={styles.input}
            />
            {resumeUrl && (
              <a href={resumeUrl} target="_blank" rel="noreferrer" download>
                View Resume
              </a>
            )}
            {resumeError && <p style={{ color: "red" }}>{resumeError}</p>}
          </div>
        ) : (
          <div>
            <p>Resume:</p>
            <p style={{ fontSize: "10px" }}>
              Resume must be in pdf format and cannot exceed 2 MB.
            </p>
            <input type="file" className={styles.input} disabled />
            {resumeUrl && (
              <a href={resumeUrl} target="_blank" rel="noreferrer" download>
                View Resume
              </a>
            )}
            {resumeError && <p style={{ color: "red" }}>{resumeError}</p>}
          </div>
        )}

        {user.emailVerified ? (
          <div>
            <button type="submit" className={styles.button}>
              Save
            </button>
          </div>
        ) : (
          ""
        )}
      </form>
    </div>
  );
};

export default CandidateProfileCard;
